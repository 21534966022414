import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
    return (
        <div className="w-64 h-screen bg-gray-800 text-white p-4">
            <h2 className="text-xl font-semibold mb-4">Categories</h2>
            <ul>
                <li className="mb-2">
                    <Link to="/category/electronics" className="hover:underline">Electronics</Link>
                </li>
                <li className="mb-2">
                    <Link to="/category/Home_appliances" className="hover:underline">Home Appliances</Link>
                </li>
                <li className="mb-2">
                    <Link to="/category/Mobile_Phones" className="hover:underline">Mobile Phones</Link>
                </li>
                <li className="mb-2">
                    <Link to="/category/computers" className="hover:underline">Computers</Link>
                </li>
                <li className="mb-2">
                    <Link to="/category/gaming" className="hover:underline">Gaming</Link>
                </li>
                <li className="mb-2">
                    <Link to="/category/accessories" className="hover:underline">Accessories</Link>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;
